// Header
import Header from './header';

// News Tabs
import NewsTabs from './news-tabs';

// Where to buy app
import WhereToBuy from './where-to-buy';

// Language Switch
import LanguageSwitch from './lang-switch';

// Article Item
import ArticleItem from './article-item';

// Video Popup
import VideoPopup from './video-list';

// Video Popup
import SectionCatcher from './section-catcher';

// More Product Block
import MoreBlock from './more-block';

// Accordion
import Accordion from './accordion';

// Denso Filter
import DensoFilter from './denso-filter';

// Innovations timeline
import Innovations from './innovations';

// Back To Top Button
import BackToTop from './back-to-top';

// Team
import Team from './team';

// Contact
import Contact from './contact';

// Locations
import Locations from './locations';

// Downloads
import Downloads from './downloads';

// Denso Slider
import DensoSlider from './denso-slider';

// Sticky Sidebar
import PartSidebar from './part-sidebar';

// Mobile Menu
import MobileMenu from './mobile-menu';

// Sentry
import SentryLog from './sentry';

// Navigation for Find your automotive parts
import Navigation from './navigation';

// Where to buy on home page
import RepairStation from './repair-station';

// Simple Page
import SimplePage from './simple-page';

// About video
import About from './about';

// Product Page
import SafetySheetsBlock from './product-block';

// Lang Banner
import LangDetectBanner from './lang-detect-banner';

// Popup geo Message
import PopupMessages from './popup-messages';

// Shop Online Modal
import ShopOnlineModal from './shop-online-modal';

// Refresh CSRF-token in forms
import FormCSRFRefresher from './form-csrf-refresher';

export default [
  Header,
  NewsTabs,
  WhereToBuy,
  LanguageSwitch,
  VideoPopup,
  SectionCatcher,
  MoreBlock,
  Innovations,
  Accordion,
  DensoFilter,
  BackToTop,
  Team,
  ArticleItem,
  Contact,
  Locations,
  Downloads,
  DensoSlider,
  PartSidebar,
  MobileMenu,
  SentryLog,
  Navigation,
  RepairStation,
  SimplePage,
  About,
  SafetySheetsBlock,
  LangDetectBanner,
  PopupMessages,
  ShopOnlineModal,
  FormCSRFRefresher,
];
